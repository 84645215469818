body {
  background-color: #F8F8F8;
  margin: 0;
  font-size: 14px;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}
a{
  text-decoration: none;
  color: #303030;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: auto;
}
header{
  background-color: #0095da;
  /* background-image: url(../public/cnseg_bg_topo.jpg); */
  height: 50px;
  width: 100%;
  padding: 0px 15%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo{
    display: block;
    background-image: url(../public/ebix_logo_branco.png);
    height: 40px;
    width: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    /* transform: scale(0.7); */
  }
}
main{
  padding: 0px 15%;
  box-sizing: border-box;
  background-color: #F8F8F8;
  .bg_home{
    width: 100%;
    height: 2000px;
    background-image: url(../public/cnseg_bg_home.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    background-position-y: top;
  }
}